// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Canvas_wrapper__3BpdO {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 80%;\n  height: 80%;\n}\n\n.Canvas_canvasBase__3ckL9 {\n  /* important for safari */\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  -webkit-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n}\n\n.Canvas_blendMode__3xTWC {\n  mix-blend-mode: difference;\n}\n\n.Canvas_normalMode__YE7Zs {\n}\n\n.Canvas_image__2T3jM {\n  -webkit-user-select: none;\n          user-select: none;\n  max-width: 100%;\n  max-height: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "Canvas_wrapper__3BpdO",
	"canvasBase": "Canvas_canvasBase__3ckL9",
	"blendMode": "Canvas_blendMode__3xTWC Canvas_canvasBase__3ckL9",
	"normalMode": "Canvas_normalMode__YE7Zs Canvas_canvasBase__3ckL9",
	"image": "Canvas_image__2T3jM"
};
module.exports = exports;
